import React, { useEffect, useCallback } from "react";
import particlesConfig from "config/particle-config";
import particlesBlackConfig from "config/pr-s-black";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import Split from "../Split";

const CustomIntro1 = ({ sliderRef, blackStar, particles }) => {
  useEffect(() => {
    setTimeout(() => {
      document
        .querySelector("#particles-js canvas")
        ?.style.removeProperty("position");
    }, 500);
  }, []);

  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
    document
      .querySelector("#particles-js canvas")
      ?.style.removeProperty("position");
  }, []);

  return (
    <>
      
          <header ref={sliderRef} className="particles circle-bg valign">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-10">
                  <div className="cont text-center">
                  <h2 className="test123 color-font">
                        Unlock brand brilliance with tailored design and digital solutions
                  </h2>
                  <br/>
                  <div className="col-lg-12 valign">
                    <div className="content">
                      <Split>
                      <p className="wow txt words chars splitting" data-splitting>
                            Choose our 360° Design Suite for all-encompassing design solutions. Ensure brand consistency, innovative visuals, and streamlined processes, driving exceptional results across branding, UI/UX, print, and digital platforms
                      </p>
                      </Split>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
            <Particles
            id="particles-js"
            init={particlesInit}
            options={blackStar ? particlesBlackConfig : particlesConfig}
          />
            <div className="gradient-circle"></div>
          <div className="gradient-circle two"></div>
          <div className="line bottom left"></div>
          </header>
          
    </>
  );
};

export default CustomIntro1;
