import React from "react";
import Navbar from "components/Navbar/navbar";
import Footer from "components/Footer/footer";
import DarkTheme from "layouts/Dark";
import { Formik, Form, Field } from "formik";
import CustomIntro1 from "components/Custom-Intros/CustomIntro1";
import emailjs from '@emailjs/browser';




const Homepage3 = () => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  const messageRef = React.useRef(null);

  
  function validateEmail(value) {
    let error;
    if (!value) {
      error = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = "Invalid email address";
    }
    return error;
  }

  function validateContact(value){
    let error;
    console.log(value+"---"+typeof value);
    if(!value)
      error = "Required";
    else if(value.length !== 10)
      error = "Enter Valid Contact No";

    return error;
  }

  const sendEmail = async (formValue, formCtrls) => {
    const emailObj = {
      from_name: formValue.name,
      to_name: "Team",
      companyName: formValue.companyname,
      contactNo: formValue.contactno,
      reply_to: formValue.email,
    }
    
    emailjs.send('service_elf_digi_studio','template_elf_stud_cont',emailObj,'Wmlon1QNJOqTWh4Ax')
    .then((result) => {
      messageRef.current.innerText = "Thank you for reaching out to us. We will get back to you soon.";
        formValue.name = "";
        formValue.email = "";
        formValue.companyname = "";
        formValue.contactno = "";
        formCtrls.resetForm();
      },
    (error) => {
      messageRef.current.innerText = "Something went wrong. Please try again!";
    }).finally(() => {
      setTimeout(() => {
        messageRef.current.innerText = ''
      }, 2000);
    });
  }

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  return (
    <DarkTheme>
      <Navbar nr={navbarRef} lr={logoRef} />
      
      <CustomIntro1/>
      
      {/* <div className='full-width gallery position-relative'>
           <img src={digitalsudio} alt="" className='digital-contact-img'/>
           <header className='digital-contact-header color-font'>Unlock brand brilliance with tailored design and digital solutions</header>
      </div> */}

      <section className="contact section-padding">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-9">
            <div className="form md-mb50 text-center">
              <h4 className="fw-700 color-font mb-50">Let's Work Together</h4>
              <Formik
                initialValues={{
                  name: "",
                  email: "",
                  companyname:"",
                  contactno:""
                }}
                onSubmit={sendEmail}
              >
                {({ errors, touched }) => (
                  <Form id="contact-form">
                    <div className="messages" ref={messageRef}></div>
                    <div className="controls">

                      <div className="form-group">
                        <Field
                          id="form_name"
                          type="text"
                          name="name"
                          placeholder="Full Name"
                          required="required"
                        />
                      </div>

                      <div className="form-group">
                        <Field
                          validate={validateEmail}
                          id="form_email"
                          type="email"
                          name="email"
                          placeholder="Email Id"
                        />
                        {errors.email && touched.email && (
                          <div>{errors.email}</div>
                        )}
                      </div>

                      <div className="form-group">
                        <Field
                          id="form_contactno"
                          type="text"
                          name="contactno"
                          placeholder="Contact Number"
                          validate={validateContact}
                        />
                        {errors.contactno && touched.contactno && (
                          <div>{errors.contactno}</div>
                        )}
                      </div>

                      <div className="form-group">
                        <Field
                          id="form_companyname"
                          type="text"
                          name="companyname"
                          placeholder="Company Name"
                          required="required"
                        />
                      </div>

                    </div>

                    <button type="submit" className="butn bord">
                      <span>Send</span>
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </section>

      <Footer hideBGCOLOR />
    </DarkTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>Elfonze Technologies - Digital Studio</title>
      <meta key="description" name="description" 
      content="Digital Studio.
      Solutions makes a resounding impact to the operational efficiencies of organization and wellness of the organizations talents." />
   
    </>
  )
}

export default Homepage3;

// import React from 'react'
// import digitalsudio from '../../../static/img/slid/digitalstudio.jpg'

// function ContactFormDesign(){
//     return(
//         <>
//         <div className='full-width gallery position-relative'>
//             <img src={digitalsudio} alt="" className='digital-contact-img'/>
//             <header className='digital-contact-header color-font'>Unlock brand brilliance with tailored design and digital solutions</header>
//         </div>
//         <div className='container'>
//             <div className='row'>
               
//             </div>
//         </div>
//         </>
//     )
// }